<template>
  <div class="school">
    <TheNavBar />

    <v-content>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import TheNavBar from "@/components/TheNavBar.vue";
export default {
  name: "School",

  components: {
    TheNavBar
  },

  data: () => ({
    connected: true
  })
};
</script>

<style></style>
